import PropTypes from 'prop-types'
import {Component} from 'react'
import {List, Map} from 'immutable'
import uuid from 'uuid'

import Container from '../../../lib/Container'
import {ensureMap, errorsMerger} from './lib/tools'
import {noop} from '../../../lib/tools'
import storePrototype from '../../../shared_components/StorePrototype'
import FieldErrors from './fields/FieldErrors'
const FORM_CONTEXT_TYPES = {
  parentGroupId: PropTypes.array,
  errors: PropTypes.func,
  data: PropTypes.func,
  shouldShowErrors: PropTypes.func,
  updateDataAndErrors: PropTypes.func
}

export {FORM_CONTEXT_TYPES} // Prevent need to rewrite these all over the place. --BLR


export default class Form extends Component {
  constructor(props) {
    super(props)

    this.uuid = uuid.v4()
    this.store = Container.registerStore(this.uuid, storePrototype())

    this.store.update({
      data: Map(),
      errors: Map(),
      shouldShowErrors: false
    })

    this.state = {storeData: {}}

    this.data = this.data.bind(this)
    this.errors = this.errors.bind(this)
    this.formData = this.formData.bind(this)
    this.isValid = this.isValid.bind(this)
    this.onUpdate = this.onUpdate.bind(this)
    this.shouldShowErrors = this.shouldShowErrors.bind(this)
    this.submit = this.onSubmit.bind(this)
    this.updateDataAndErrors = this.updateDataAndErrors.bind(this)
  }

  componentDidMount() { this.store.addChangeListener(this.onUpdate) }

  // Force form to update on store change:
  onUpdate() {
    this.setState({storeData: this.storeData()})
    if (this.props.onUpdate)
      this.props.onUpdate() // need to run this around the team. The idea is that I want to decouple form event from a data event change
  }

  componentWillUnmount() { this.store.removeChangeListener(this.onUpdate) }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newErrors = ensureMap(nextProps.errors)
    if (newErrors.size) {
      const oldStoreData = this.storeData()
      this.store.update({
        ...oldStoreData,
        errors: oldStoreData.errors.mergeWith(errorsMerger, newErrors),
        shouldShowErrors: true
      })
    }
  }

  getChildContext() {
    return {
      errors: this.errors,
      data: this.data,
      parentGroupId: this.props.groupId,
      shouldShowErrors: this.shouldShowErrors,
      updateDataAndErrors: this.updateDataAndErrors
    }
  }

  storeData() { return this.store.getData() }

  isValid() {
    return !this.storeData().errors.toList().filter(error => (List.isList(error) ? error.size : error)).size && !this.props.showFormLevelError
  }

  onSubmit(event) {
    if (event)
      event.preventDefault()

    const storeData = this.storeData()

    if (this.isValid()) {
      this.store.update({
        data: storeData.data,
        errors: Map(),
        shouldShowErrors: false
      })

      return storeData.data
    }

    this.store.update({
      ...storeData,
      shouldShowErrors: true
    })
  }

  // Methods used by the parent of the Form:
  formData() { return this.storeData().data }

  // Methods used by descendants:
  data(id) { return this.storeData().data.get(id) }

  errors(id) {
    const storeData = this.storeData()
    const storeErrors = storeData.errors.get(id)
    return List().concat(
      Map.isMap(storeErrors) ? List([storeErrors]) : storeErrors
    ).filter(Boolean)
  }

  shouldShowErrors() { return this.storeData().shouldShowErrors || this.props.showFormLevelError }

  updateDataAndErrors(id, data, errors) {
    const oldStoreData = this.storeData()
    this.store.update({
      data: oldStoreData.data.set(id, ensureMap(data)),
      errors: oldStoreData.errors.set(id, errors),
      shouldShowErrors: oldStoreData.shouldShowErrors
    })

    this.props.onUpdate()
  }

  invalidFormErrors() {
    // We are allowing different data types here to account for other components that are already sending down the data this way.
    if (this.props.invalidFormError)
      return List.isList(this.props.invalidFormError) ? this.props.invalidFormError : List([this.props.invalidFormError])
    else
      return List(['Please address the issues above and try again.'])
  }

  render() {
    const {children, className} = this.props

    return (
      <span>
        <form className={className} onSubmit={this.onSubmit}>
          {children}
          {
            this.shouldShowErrors() &&
            !this.isValid() &&
            (
              <FieldErrors errors={this.invalidFormErrors()} />
            )
          }
        </form>
      </span>
    )
  }
}

Form.childContextTypes = FORM_CONTEXT_TYPES

Form.propTypes = {
  errors: PropTypes.oneOfType([
    Map,
    PropTypes.object
  ]),
  groupId: PropTypes.string,
  invalidFormError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(List)
  ]),
  onUpdate: PropTypes.func,
  showFormLevelError: PropTypes.bool
}

Form.defaultProps = {
  onUpdate: noop
}
